.body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f4f7fa;
  color: #333;
}

h3 {
  font-size: 1.5em;
  color: #2c3e50;
  margin-bottom: 16px;
}

div {
  display: flex;
  align-items: center;
}

.draggable-item {
  padding: 12px;
  margin-bottom: 10px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 300px;
  transition: transform 0.2s ease;
  cursor: grab;
}

.draggable-item:active {
  cursor: grabbing;
  transform: scale(1.02);
}

.draggable-item:hover {
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
}

.player-table {
  margin-top: 20px;
  width: 100%;
  max-width: 400px;
  padding: 20px;
  background-color: #ecf0f1;
  border-radius: 10px;
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
}

.player-table h3 {
  margin-bottom: 10px;
  color: #34495e;
}

.table {
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  background-color: #ecf0f1;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
}

.table-row {
  padding: 12px;
  margin-bottom: 10px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease;
}

.table-row:hover {
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
  transform: scale(1.02);
}
